<template>
  <div id="app">
    <GChart
      v-if="tableChartData.length"
      ref="gChart"
      :settings="{ packages: ['table'] }"
      :data="chartData"
      :options="chartOptions"
      :create-chart="(el, google) => new google.visualization.Table(el || $el)"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { GChart } from 'vue-google-charts';
import { mapGetters } from 'vuex';

export default {
  name: 'TableChart',
  components: {
    GChart,
  },

  computed: {
    ...mapGetters([
      'user',
      'selectedProjectsForChart',
      'dateForChart',
      'tableChartData',
      'filteredProjectsForCharts',
    ]),
    formatTableChartData() {
      return this.tableChartData.map(el => {
        const project = this.filteredProjectsForCharts.find(pr => el[0] === pr.name);

        return [
          el[0],
          el[1].description,
          {
            v: el[2],
            f: (el[2] > 60 && moment.duration(el[2], 'seconds').minutes() > 0)
              ? `${Math.floor(moment.duration(el[2], 'seconds').asHours())}h
              ${moment.duration(el[2], 'seconds').minutes()}min`
              : ''
              || ((el[2] > 60 && moment.duration(el[2], 'seconds').minutes() === 0
                && moment.duration(el[2], 'seconds').asHours() > 1)
                ? `${Math.floor(moment.duration(el[2], 'seconds').asHours())} hours`
                : '')
              || ((el[2] > 60 && moment.duration(el[2], 'seconds').minutes() === 0)
                ? `${Math.floor(moment.duration(el[2], 'seconds').asHours())} hour`
                : ''),
          },
          {
            v: el[3],
            f: `${Math.floor(moment.duration(el[3], 'seconds').asHours())}h
              ${moment.duration(el[3], 'seconds').minutes()}m
              ${moment.duration(el[3], 'seconds').seconds()}s`,
          },
          {
            v: el[5],
            f: (el[5] !== 0) ? `${el[4]} ${parseFloat(el[5].toFixed(project.currency.decimalDigits))}` : '',
          },
        ];
      });
    },
    chartData() {
      return [
        [
          {
            label: 'Project',
          },
          {
            label: 'Task',
          },
          {
            label: 'ETA',
          },
          {
            label: 'Duration',
          },
          {
            label: 'Earnings',
          },
        ],
        ...this.formatTableChartData,
      ];
    },
    chartOptions() {
      return {
        tableRow: {
          color: 'red',
        },
        showRowNumber: true,
        width: '100%',
        height: '100%',
      };
    },
  },
};
</script>

<style scoped>
  /*.color-style {*/
  /*color: red;*/
  /*background: #409EFF;*/
  /*}*/
</style>
